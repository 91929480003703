/**
 * Created by Administrator on 2019/1/2.
 */
export default {
  state: {
    Tabs: [], //所有打开的路由
    ActiveIndex: "/resultanalysis", //激活状态
  },
  mutations: {
    // 添加tabs
    add_tabs(state, data) {
      state.Tabs.push(data);
    },
    // 删除tabs
    delete_tabs(state, route) {
      let index = 0;
      for (let option of state.Tabs) {
        if (option.route === route) {
          break;
        }
        index++;
      }
      state.Tabs.splice(index, 1);
    },
    // 删除全部tabs
    delete_tabs_all(state, data) {
      state.ActiveIndex = "/resultanalysis";
      state.Tabs = [data];
    },
    // 设置当前激活的tab
    set_active_index(state, index) {
      state.ActiveIndex = index;
    },
    clear_tabs(state) {
      state.Tabs = [];
      state.ActiveIndex = "/resultanalysis";
    }
  },
  actions: {
    //....
  }
};
