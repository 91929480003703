/**
 * Created by Administrator on 2019/1/2.
 */
export default {
    state: {
      //state
      stageList: [],
      periodList: [],
      stageOptions:[],
      basketList:[],
      subjectList:[],
      subjectTotalSelect: []
    },
    mutations: {
      setStageList(state, data) {
        //这里的state对应着上面这个state
        state.stageList = data;
        //你还可以在这里执行其他的操作改变state
      },
      setPeriodList(state, data) {
        state.periodList = data;
      },
      setStageOptions(state, data) {
        state.stageOptions = data;
      },
      setBasketList(state,data) {
        state.basketList = data;
      },
      set_subject_list(state,data) {
        state.subjectList = data;
      },
      set_subject_select(state,data) {
        state.subjectTotalSelect = data;
      }
    }
  };
  