import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
import Tabs from "./path/Tabs"; //页面横向导航
import Login from "./path/Login"; //登录验证
import Main from "./path/main"; 
export default new Vuex.Store({
  modules: {
    Tabs,
    Login,
    Main
  }
});
