/**
 * Created by Administrator on 2019/1/2.
 */

//家校互动
// const HSInteraction = resolve => require(["@/views/HSInteraction/HSInteraction.vue"],resolve);
//随堂作业
const ClassRoomWork = resolve => require(["@/views/ClassRoomWork/index.vue"],resolve);
//学情分析
const SituationAnalysis = resolve => require(["@/views/SituationAnalysis/index.vue"],resolve);
//教学报表
const TeachReport = resolve => require(["@/views/TeachReport/index.vue"],resolve);
//心理测验
const PsychologyTest = resolve => require(["@/views/PsychologyTest/index.vue"],resolve);
//试卷评价
const PaperEvaluation = resolve => require(["@/views/PaperEvaluation/index.vue"],resolve);
//任课教师查询
const TeacherQuery = resolve => require(["@/views/TeacherQuery/TeacherList.vue"],resolve);
//体温上报
const TemperatureReport = resolve => require(["@/views/TemperatureReport/index.vue"],resolve);

//教师分析
const TeacherAnalysis = resolve => require(["@/views/StatisticAnalysis/TeacherAnalysis.vue"],resolve);

import ClassRoomWorkRouterChild from '@/routes/path/ClassRoomWorkRouterChild'
import SituationAnalysisRouterChild from '@/routes/path/SituationAnalysisRouterChild'
import TeachReportRouterChild from "@/routes/path/TeachReportRouterChild"
import TemperatureReportRouterChild from "@/routes/path/TemperatureReportRouterChild"

import PsychologyTestRouterChild from "@/routes/path/PsychologyTestRouterChild"
import PaperEvaluationRouterChild from "@/routes/path/PaperEvaluationRouterChild"

const routes = [
  {
    path: "/",
    redirect: "/ClassRoomWork"
  },
  {
    path: "/classroomwork",
    component: ClassRoomWork,
    children: ClassRoomWorkRouterChild,
    meta: {
      title: "高效刷题器管理系统",
      auth: 0
    },
  },
  {
    path: "/situationanalysis",
    component: SituationAnalysis,
    children:SituationAnalysisRouterChild,
    meta: {
      title: "高效刷题器管理系统",
      auth: 0
    },
  },
  {
    path: "/teachreport",
    component: TeachReport,
    children:TeachReportRouterChild,
    meta: {
      title: "高效刷题器管理系统",
      auth: 0
    },
  },
  {
    path: "/psychologytest",
    component: PsychologyTest,
    children:PsychologyTestRouterChild,
    meta: {
      title: "高效刷题器管理系统",
      auth: 0
    },
  },
  {
    path: "/paperevaluation",
    component: PaperEvaluation,
    children:PaperEvaluationRouterChild,
    meta: {
      title: "高效刷题器管理系统",
      auth: 0
    },
  },
  {
    path: "/queryteacher",
    component: TeacherQuery,
    meta: {
      title: "高效刷题器管理系统",
      auth: 0
    },
  },
  {
    path: "/temperaturereport",
    component: TemperatureReport,
    children:TemperatureReportRouterChild,
    meta: {
      title: "高效刷题器管理系统",
      auth: 0
    },
  },
  {
    path: "/teacheranalysis1",
    component: TeacherAnalysis,
    meta: {
      title: "高效刷题器管理系统",
      auth: 0
    },
  }
  
];
export default routes;
