/**
 * Created by Administrator on 2019/1/2.
 */
//作业列表
const WorkList = resolve => require(["@/views/SituationAnalysis/WorkList.vue"], resolve);
//随堂作业
const ClassroomWorkManage = resolve => require(["@/views/SituationAnalysis/ClassroomWorkManage.vue"], resolve);

const routes = [
  {
    path: "/",
    redirect: "worklist"
  },
  {
    path: "worklist",
    name: "作业列表",
    component: WorkList,
    meta: {
      title: "高效刷题器管理系统",
      auth: 0,
      keepAlive: true,
    }
  },
  {
    path: "classroomworkmanage",
    name: "随堂作业",
    component: ClassroomWorkManage,
    meta: {
      title: "高效刷题器管理系统",
      auth: 0,
      keepAlive: false,
    }
  }
];
export default routes;
