/**
 * Created by Administrator on 2019/1/2.
 */
export default {
  state: {
    //state
    isLogin: localStorage.getItem('isManageLogin'),
    data: {}
  },
  mutations: {
    isLoginChange(state,data) {
      localStorage.setItem('isManageLogin', data);
      state.isLogin = data;
    },
    setLogin(state, data) {
      //这里的state对应着上面这个state
      state.data = Object.assign(state.data, data);
      //你还可以在这里执行其他的操作改变state
    }
  }
};
