import Vue from "vue";
import VueRouter from "vue-router";

const Home = resolve => require(["@/views/Home/Home.vue"], resolve);
const SchoolLogin = resolve => require(["@/views/Login/SchoolLogin.vue"], resolve);
const Login = resolve => require(["@/views/Login/Login.vue"], resolve);
const SchoolHome = resolve => require(["@/views/Home/SchoolHome.vue"],resolve);

// 例
import RouterChild from "@/routes/path/RouterChild";
import SchoolRouterChild from "@/routes/path/SchoolRouterChild";

Vue.use(VueRouter);

//重写push方法
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push (location) {
  return routerPush.call(this, location).catch(error => error);
};



const routes = [
  {
    path: "/",
    redirect: "/login"
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      title: "高效刷题器管理系统",
      auth: 0
    }
  },
  {
    path: "/schoollogin",
    name: "schoollogin",
    component: SchoolLogin,
    meta: {
      title: "高效刷题器管理系统",
      auth: 0
    }
  },
  {
    path: "/home",
    component: Home,
    meta: {
      title: "高效刷题器管理系统",
      auth: 1
    },
    children: RouterChild
  },
  {
    path: "/schoolhome",
    component: SchoolHome,
    meta: {
      title: "高效刷题器管理系统",
      auth: 1
    },
    children: SchoolRouterChild
  },
];

const router = new VueRouter({
  routes
});

// router.beforeEach((to, from, next) => {
//   if (to.path == "/" || to.path == "/login"|| to.path == "/schoollogin"|| to.path == "/adminlogin") {
//     next();
//   } else {
//     var islogin = JSON.parse(localStorage.getItem('loginInfo')) 
//     if (islogin) {
//       next();
//     } else {
//       Vue.prototype.$message({
//         message: '您尚未登录，请登录后再访问！',
//         type: 'warning'
//       });
//       if(to.path == '/resultanalysis'){next({ path: '/login' })}
//       else if(to.path == '/managegrage'){next({ path: '/schoollogin' })}
//       else next({ path: '/login' })
//     }
//   }
// });
router.afterEach(to => {
  if (to.meta.title) {
    document.title = to.meta.title;
    // 如果是 iOS 设备
    if (navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
      const newPage = document.createElement("iframe");
      newPage.style.display = "none";
      document.body.appendChild(newPage);
      setTimeout(() => {
        document.body.removeChild(newPage);
      }, 300);
    }
  }
});

export default router;
