import Vue from "vue";
import App from "./App.vue";
import router from "./routes/router";
import store from "./stores/store";
import axios from "axios";

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '../element-variables.scss'

Vue.config.productionTip = false;

import echarts from "echarts";
Vue.prototype.echarts = echarts;
import dataTool  from 'echarts/extension/dataTool'

import CryptoJS from "crypto-js";//加密

Vue.use(ElementUI);
Vue.use(CryptoJS);

// import 'lib-flexible'//适配不同的屏幕

import $ from 'jquery'

import {
  message
} from './plugins/resetMessage';
Vue.prototype.$message = message;

// 导入打印
import Print from 'vue-print-nb'
// import Print from '@/plugins/print'

Vue.use(Print)



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
