
//体温报表
const TemperatureReportManage = resolve => require(["@/views/TemperatureReport/TemperatureReportManage.vue"], resolve);

const routes = [
  {
    path: "/",
    redirect: "temperaturereportmanage"
  },
  {
    path: "temperaturereportmanage",
    name: "temperaturereportmanage",
    component: TemperatureReportManage,
    meta: {
      title: "高效刷题器管理系统",
      auth: 0
    }
  }
];
export default routes;