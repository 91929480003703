/**
 * Created by Administrator on 2019/1/2.
 */
//直接设置
const DirectSettingWork = resolve => require(["@/views/ClassRoomWork/Child/DirectSettingWork.vue"], resolve);
//组卷
const ComposeWork = resolve => require(["@/views/ClassRoomWork/Child/ComposeWork.vue"], resolve);


const routes = [
  {
    path: "/",
    redirect: "directsettingwork"
  },
  {
    path: "directsettingwork",
    name: "直接设置",
    component: DirectSettingWork,
    meta: {
      title: "高效刷题器管理系统",
      auth: 0
    }
  },
  {
    path: "composework",
    name: "组卷",
    component: ComposeWork,
    meta: {
      title: "高效刷题器管理系统",
      auth: 0
    }
  }
];
export default routes;
