/**
 * Created by Administrator on 2019/1/2.
 */
//年级管理
const GradeManage = resolve => require(["@/views/SchoolManagePages/GradeManage.vue"], resolve);
//班级管理
const ClassManage = resolve => require(["@/views/SchoolManagePages/ClassManage.vue"], resolve);
//科目管理
const SubjectManage = resolve => require(["@/views/SchoolManagePages/SubjectManage.vue"], resolve);
//班级属性
const ClassAttributeManage = resolve => require(["@/views/SchoolManagePages/ClassAttributeManage.vue"], resolve);
//班级设备管理
const ClassEquipManage = resolve => require(["@/views/SchoolManagePages/ClassEquipManage.vue"], resolve);
//教师信息管理
const TeacherManage = resolve => require(["@/views/SchoolManagePages/TeacherManage.vue"], resolve);
//学生信息管理
const StudentManage = resolve => require(["@/views/SchoolManagePages/StudentManage.vue"], resolve);
//学生选课信息管理
const StudentSelectManage = resolve => require(["@/views/SchoolManagePages/StudentSelectManage.vue"], resolve);
//学生SN码管理
const StudentSNCodeManage = resolve => require(["@/views/SchoolManagePages/StudentSNCodeManage.vue"], resolve);
//学生账号管理
const StudentAccountManage = resolve => require(["@/views/SchoolManagePages/StudentAccountManage.vue"], resolve);
//考试管理
const ExamManage = resolve => require(["@/views/SchoolManagePages/ExamManage.vue"], resolve);
//题库管理
const QuestionsManage = resolve => require(["@/views/SchoolManagePages/QuestionsManage.vue"], resolve);
//体温上报规则
const TemperatureRule = resolve => require(["@/views/SchoolManagePages/TemperatureRule.vue"], resolve)
//体温报表
const ReportTemperature = resolve => require(["@/views/SchoolManagePages/ReportTemperature.vue"], resolve)
//学生体温管理
const StudentTemperatureManage = resolve => require(["@/views/SchoolManagePages/StudentTemperatureManage.vue"], resolve)
//异常体温统计
const AbnormalTemperature = resolve => require(["@/views/SchoolManagePages/AbnormalTemperature.vue"], resolve)
//班级评价-评价方案定义
const ClassEvaluationManage = resolve => require(["@/views/SchoolManagePages/ClassEvaluationManage.vue"], resolve)

//班级评价-评价生成
const ClassEvaluationBind = resolve => require(["@/views/SchoolManagePages/ClassEvaluationBind.vue"], resolve)

//统计分析-班级分析
const ClassAnalysis = resolve => require(["@/views/SchoolManagePages/StatisticAnalysis/ClassAnalysis.vue"], resolve)
//统计分析-教师分析
const TeacherAnalysis = resolve => require(["@/views/SchoolManagePages/StatisticAnalysis/TeacherAnalysis.vue"], resolve)

const routes = [
  {
    path: "/",
    redirect: "/grademanage/normallogin"
  },
  {
    path: "/grademanage/:type",
    name: "年级管理",
    component: GradeManage,
    meta: {
      title: "高效刷题器管理系统",
      auth: 0
    }
  },
  {
    path: "/classmanage",
    name: "班级管理",
    component: ClassManage,
    meta: {
      title: "高效刷题器管理系统",
      auth: 0
    }
  },
  {
    path: "/subjectmanage",
    name: "科目管理",
    component: SubjectManage,
    meta: {
      title: "高效刷题器管理系统",
      auth: 0
    }
  },
  {
    path: "/classattribute",
    name: "班级属性",
    component: ClassAttributeManage,
    meta: {
      title: "高效刷题器管理系统",
      auth: 0
    }
  },
  {
    path: "/classequipmanage",
    name: "班级设备管理",
    component: ClassEquipManage,
    meta: {
      title: "高效刷题器管理系统",
      auth: 0
    }
  },
  {
    path: "/teachermanage",
    name: "教师信息管理",
    component: TeacherManage,
    meta: {
      title: "高效刷题器管理系统",
      auth: 0
    }
  },
  {
    path: "/studentmanage",
    name: "学生信息管理",
    component: StudentManage,
    meta: {
      title: "高效刷题器管理系统",
      auth: 0
    }
  },
  {
    path: "/studentselectmanage",
    name: "学生信息管理",
    component: StudentSelectManage,
    meta: {
      title: "高效刷题器管理系统",
      auth: 0
    }
  },
  {
    path: "/studentsncodemanage",
    name: "学生SN码管理",
    component: StudentSNCodeManage,
    meta: {
      title: "高效刷题器管理系统",
      auth: 0
    }
  },
  {
    path: "/studentaccountmanage",
    name: "学生账号管理",
    component: StudentAccountManage,
    meta: {
      title: "高效刷题器管理系统",
      auth: 0
    }
  },
  {
    path: "/exammanage",
    name: "exammanage",
    component: ExamManage,
    meta: {
      title: "高效刷题器管理系统",
      auth: 0
    }
  },
  {
    path: "/questionsmanage",
    name: "questionsmanage",
    component: QuestionsManage,
    meta: {
      title: "高效刷题器管理系统",
      auth: 0
    }
  },
  {
    path: "/temperaturerule",
    name: "temperaturerule",
    component: TemperatureRule,
    meta: {
      title: "高效刷题器管理系统",
      auth: 0
    }
  },
  {
    path: "/reporttemperature",
    name: "reporttemperature",
    component: ReportTemperature,
    meta: {
      title: "高效刷题器管理系统",
      auth: 0
    }
  },
  {
    path: "/studenttemperaturemanage",
    name: "studenttemperaturemanage",
    component: StudentTemperatureManage,
    meta: {
      title: "高效刷题器管理系统",
      auth: 0
    }
  },
  {
    path: "/abnormaltemperaturemanage",
    name: "abnormaltemperaturemanage",
    component: AbnormalTemperature,
    meta: {
      title: "高效刷题器管理系统",
      auth: 0
    }
  },
  {
    path: "/classevaluationmanage",
    name: "classevaluationmanage",
    component: ClassEvaluationManage,
    meta: {
      title: "高效刷题器管理系统",
      auth: 0
    }
  },
  {
    path: "/classevaluationbind",
    name: "classevaluationbind",
    component: ClassEvaluationBind,
    meta: {
      title: "高效刷题器管理系统",
      auth: 0
    }
  },
  {
    path: "/classanalysis",
    name: "classanalysis",
    component: ClassAnalysis,
    meta: {
      title: "高效刷题器管理系统",
      auth: 0
    }
  },
  {
    path: "/teacheranalysis",
    name: "teacheranalysis",
    component: TeacherAnalysis,
    meta: {
      title: "高效刷题器管理系统",
      auth: 0
    }
  }
  
  
];
export default routes;
