//作业列表
const WorkList = resolve => require(["@/views/TeachReport/WorkList.vue"], resolve);

//作业报表
const ReportManage = resolve => require(["@/views/TeachReport/ReportManage.vue"], resolve);

const routes = [
  {
    path: "/",
    redirect: "worklist"
  },
  {
    path: "worklist",
    name: "worklist",
    component: WorkList,
    meta: {
      title: "高效刷题器管理系统",
      auth: 0,
      keepAlive: true,
    }
  },
  {
    path: "reportmanage",
    name: "教学报表",
    component: ReportManage,
    meta: {
      title: "高效刷题器管理系统",
      auth: 0,
      keepAlive: false,
    }
  },
];
export default routes;